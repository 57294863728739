import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { fonts, colors } from '../theme/variables';
import TopHeader from '../components/topHeader';
import FirstView from '../components/first-view';
import MainText from '../components/main-text';
import PrimaryButton from '../components/primary-button';

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
`;

const IndexPage = ({ data }) => {
  const { markdownRemark } = data;
  const { html } = markdownRemark;
  return (
    <>
      <TopHeader desktop />
      <Layout home>
        <SEO
          title="Főoldal"
          keywords={[`Béres autósiskola`, `salgótarján autósiskola`, `salgótarjáni autósiskola`, `autósiskola`, `autós iskola`, `kresz`, `jogosítvány`]}
        />
        <FirstView />
        <MainText dangerouslySetInnerHTML={{ __html: html }} />
        <Center>
          <PrimaryButton as={Link} to="/about" outline="true">Bővebben</PrimaryButton>
        </Center>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    markdownRemark(
      fileAbsolutePath: { regex: "beresautosiskola/content/home/g" }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default IndexPage;
