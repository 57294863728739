import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import {
  colors,
  fonts,
  breakPoints,
  headerHeight,
  topHeaderHeight,
} from '../theme/variables';
import ContactForm from './contact-form';
import PrimaryButton from './primary-button';
import TopHeader from './topHeader';
import ArrowIcon from '../images/arrow.svg';

const StyledFirstView = styled.header`
  display: flex;
  flex-direction: column;
  background-color: ${colors.primaryLighten};
  padding: 5.4rem 3.6rem 0;
  min-height: 52.2rem;
  height: calc(100vh - ${headerHeight.normal} - ${topHeaderHeight.normal});
  position: relative;

  @media (max-width: ${breakPoints.first}) {
    height: initial;
    padding: 2em 1em 2em;
    min-height: calc(100vh - ${headerHeight.small});
    height: initial;
    padding-top: 4em;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${breakPoints.first}) {
    flex-direction: column;
  }
`;

const ContactHolder = styled.div`
  flex: 5;
  display: flex;
  justify-content: center;

  @media (max-width: ${breakPoints.first}) {
    order: 1;
  }
`;

const TextBox = styled.div`
  color: ${colors.white};
  letter-spacing: 0.07em;
  flex: 6;
  text-align: right;

  @media (max-width: ${breakPoints.first}) {
    order: 0;
    text-align: center;
    margin-bottom: 2.5em;
  }
`;

const HeadingPrimary = styled.h2`
  font-family: ${fonts.sourceSansPro};
  font-weight: 600;
  font-size: ${({second}) => second ? '3rem' : '3.5rem'};
  margin-top: ${({second}) => second ? '2rem' : ''};

  span {
    text-decoration: underline;
  }

  @media (max-width: ${breakPoints.first}) {
    font-size: ${({second}) => second ? '2.4rem' : '3rem'};
  }

  @media (max-width: ${breakPoints.mobile}) {
    font-size: ${({second}) => second ? '3rem' : '3.5rem'};
    margin-bottom: 0.5em;
  }

  a {
    color: ${colors.white};
    text-decoration: underline;
  }
`;

const HeadingSecondary = styled.h3`
  font-family: ${fonts.Nunito};
  font-size: 2.6rem;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: ${breakPoints.first}) {
    font-size: 2.2rem;
  }
`;

const EmbedText = styled.span`
  font-size: 2rem;
  display: block;
  margin: 0.85em 0 1.4em;

  @media (max-width: ${breakPoints.first}) {
    font-size: inherit;
  }

  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.75rem;
    margin-bottom: 1.8em;
  }
`;

const NextDate = styled.span`
  color: ${colors.accent};

  @media (max-width: ${breakPoints.mobile}) {
    display: block;
  }
`;

const Price = styled.strong`
  color: ${colors.accent};
`;

const ApplyButton = styled(PrimaryButton)`
  width: 85vw;
  font-size: 1.6rem;
  display: none;

  @media (max-width: ${breakPoints.mobile}) {
    display: block;
  }
`;

const Arrow = styled.img`
  width: 2.5rem;
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);

  @media (max-width: ${breakPoints.first}) {
    display: none;
  }
`;

const CourseNumber = styled.p`
  margin-bottom: 1rem;
  span {
    white-space: nowrap;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const OpenHours = styled.div`
  color: ${colors.white};
  margin-top: 3rem;
  text-align: center;

  ${HeadingSecondary} {
    margin-bottom: 1rem;
  }

  ${EmbedText} {
    margin: 0;
    line-height: 1.5;
  }
`;

const FirstView = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(course)|(open_hours)/" } }) {
          nodes {
            frontmatter {
              datetime
              text
              title
              hours
            }
          }
        }
      }
    `}
    render={(data) => {
      const [course, ...openHours] = data.allMarkdownRemark.nodes.map(node => node.frontmatter);
      const { datetime, text } = course;
      const hasDate = datetime && datetime !== 'Invalid date';
      const openHoursContent = (openHours.find(hour => hour.title === 'Ügyfélfogadási idő') || openHours.find(hour => hour.hours)).hours;
      console.log(openHoursContent)
      return (
        <StyledFirstView>
          <Top>
            <ContactHolder>
              <LeftContent>
                <ContactForm home />
                <ApplyButton as={Link} to="/apply">
                  Jelentkezés
                </ApplyButton>
                <OpenHours>
                  <HeadingSecondary>Ügyfélfogadási idő:</HeadingSecondary>
                  <EmbedText dangerouslySetInnerHTML={{ __html: openHoursContent.replace('\\', '<br />') }}/>
                </OpenHours>
              </LeftContent>
            </ContactHolder>
            <TextBox>
              <CourseNumber>
                Felnőttképzési nyilvántartási szám: B/2020/001383
              </CourseNumber>
              <HeadingSecondary>
                Jelentkezz most{' '}
                <EmbedText>
                  és használd ki a <Price>25.000 Ft</Price>-os állami
                  támogatást!
                </EmbedText>
              </HeadingSecondary>
              {
                hasDate
                  ? (
                    <HeadingPrimary>
                      <span>Következő tanfolyam:</span> <NextDate>{datetime}</NextDate>
                    </HeadingPrimary>
                  )
                  : null
              }
              {
                text
                  ? <HeadingPrimary as={hasDate ? 'h3' : 'h2'} second={hasDate.toString()}>{text}</HeadingPrimary>
                  : null
              }
            </TextBox>
          </Top>
          <Arrow src={ArrowIcon} alt="Nyíl ikon" />
          <TopHeader marginTop="4rem" tablet />
        </StyledFirstView>
      );
    }}
  />
);

export default FirstView;
