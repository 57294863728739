import React, { Component } from 'react';
import { graphql, StaticQuery, navigate } from 'gatsby';
import styled from 'styled-components';
import { colors, fonts, breakPoints } from '../theme/variables';
import PrimaryButton from './primary-button';
import PrimaryLink from './primary-link';
import ContactIcon from '../images/user.svg';
import MailIcon from '../images/mail_v2.svg';
import PhoneIcon from '../images/phone_v2.svg';
import LocationIcon from '../images/location.svg';
import CategoryIcon from '../images/notepad.svg';
import DateIcon from '../images/date_v2.svg';
import ArrowIcon from '../images/arrow_v2.svg';
import CarIcon from '../images/car.svg';
import PenIcon from '../images/edit.svg';
import SubjectIcon from '../images/text.svg';
import MessageIcon from '../images/notepad.svg';

const fromBackgroundColor = '#7093C2';
const inputBackgroundColor = 'rgba(46, 72, 107, 0.5)';

const StyledContactForm = styled.form`
  width: 40.2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 10px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakPoints.mobile}) {
    ${props =>
      props.home &&
      `
      display: none;
    `}
  }

  ${props => (
    props.apply ?
    `
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 0;
    @media (max-width: ${breakPoints.first}) {
      width: 38.2rem;
    }
  `
  : ``)}
`;

const Header = styled.header`
  background-color: ${colors.white};
  padding: 1.7rem 2.5rem;
`;

const HeaderIcon = styled.img`
  position: absolute;
`;

const HeaderText = styled.p`
  font-family: ${fonts.Nunito};
  font-size: 1.6rem;
  font-weight: 700;
  color: ${colors.primary};
  letter-spacing: 0.07em;
  text-transform: uppercase;
  text-align: center;
`;

const FormBody = styled.div`
  background-color: ${fromBackgroundColor};
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.label`
  display: block;
  position: relative;

  & + & {
    margin-top: 1.8rem;
  }
`;

const Icon = styled.img`
  width: auto;
  height: 14px;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(-50%, -50%);

  ${props =>
    props.textAreaIcon &&
    `
    top: 7px;
    left: 20px;
    transform: translateX(-50%);
  `}
`;

const Arrow = styled.img`
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = styled.input`
  background-color: ${inputBackgroundColor};
  width: 100%;
  height: 2.8rem;
  padding-left: 4.1rem;
  border: 0;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
  }

  &,
  ::placeholder {
    font-family: ${fonts.sourceSansPro};
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    color: ${colors.white};
    opacity: 1;
  }

  &:disabled {
    color: #999999;
  }

  ${props =>
    props.error &&
    `
    border: 1px solid ${colors.error};
  `}
`;

const Select = styled.select`
  background-color: ${inputBackgroundColor};
  width: 100%;
  height: 2.8rem;
  padding-left: 4.1rem;
  border: 0;
  border-radius: 0;
  appearance: none;
  outline: 1px inset transparent;
  outline-offset: -1px;

  /* CAUTION: IE hackery ahead */
  &::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }

  &,
  ::placeholder {
    font-family: ${fonts.sourceSansPro};
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    color: ${colors.white};
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.1rem;
  margin-top: auto;

  ${props =>
    props.center &&
    `
    justify-content: center;
  `}
`;

const TextArea = styled.textarea`
  background-color: rgba(46, 72, 107, 0.5);
  width: 100%;
  padding-left: 4.1rem;
  border: 0;
  padding-top: 7px;

  &,
  ::placeholder {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
`;

const ErrorText = styled.span`
  color: ${colors.error};
  margin-top: 0.125em;
  margin-bottom: 0.625em;
`;

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)  }=${  encodeURIComponent(data[key])}`)
    .join('&');
}

export default class ContactForm extends Component {
  constructor(props) {
    super(props);

    if (props.personalMessage) {
      this.state = {
        inputs: {
          név: '',
          email: '',
          telefonszám: '',
          település: '',
          tárgy: '',
          üzenet: '',
        },
        hasError: false,
      };
    } else {
      this.state = {
        inputs: {
          név: '',
          email: '',
          telefonszám: '',
          település: '',
          kategória: 'default',
          típus: 'default',
        },
        hasError: false,
      };
    }
    this.oldState = this.state;
  }

  hasErrorState = () => {
    if (!this.state.errors) {
      return false;
    }
    const errorStates = Object.keys(this.state.errors).filter(
      errorKey => this.state.errors[errorKey] != ''
    );
    return errorStates.length > 0;
  };

  handleChange = e => {
    this.setState({
      inputs: { ...this.state.inputs, [e.target.name]: e.target.value },
      errors: { ...this.state.errors, [e.target.name]: '' },
    });
  };

  sendApplication = (form) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state.inputs,
      }),
    })
      .then(() => {
        navigate('/thank-you', {
          state: { message: 'Sikeresen jelentkezett!' },
        });
      })
      .catch(error => alert(error));
  }

  handleSubmit = e => {
    e.preventDefault();

    const errorInputKeys = Object.keys(this.state.inputs).filter(
      inputKey =>
        this.state.inputs[inputKey] === '' ||
        this.state.inputs[inputKey] === 'default'
    );

    if (errorInputKeys.length > 0) {
      const errors = {};
      errorInputKeys.forEach(errorInputKey => {
        errors[errorInputKey] = `Hiányzó ${errorInputKey}!`;
      });

      this.setState({ errors, hasError: true });
    } else {
      const form = e.target;
      if (this.props.personalMessage) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...this.state.inputs,
          }),
        })
          .then(() => {
            navigate('/thank-you', {
              state: { message: 'Üzenetét sikeresen továbbítottuk!' },
            });
          })
          .catch(error => alert(error));
      } else {
        const dateState = form.querySelector('input[name="dátum"]');
        if (!dateState || (this.state.inputs.típus && this.state.inputs.típus === 'Online oktatás' )) {
          this.sendApplication(form);
        } else {
          this.setState(
            {
              inputs: { ...this.state.inputs, [dateState.name]: dateState.value },
            },
            this.sendApplication(form)
          );
        }
      }
    }
  };

  render() {
    const { home, apply, personalMessage } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/course/" } }) {
              nodes {
                frontmatter {
                  datetime
                  text
                  title                  
                }
              }
            }
          }
        `}
        render={data => {
          const { frontmatter } = data.allMarkdownRemark.nodes[0];
          const { datetime, text } = frontmatter;
          const hasDate = datetime && datetime !== 'Invalid date';
          const headerIconSrc = personalMessage ? PenIcon : CarIcon;
          return (
            <StyledContactForm
              home={home}
              apply={(!!apply).toString()}
              name={personalMessage ? 'Levél' : 'Jelentkezés'}
              method="post"
              action="/"
              data-netlify="true"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <Header>
                <HeaderIcon
                  src={headerIconSrc}
                  alt={personalMessage ? 'Toll ikon' : 'Autó ikon'}
                />
                <HeaderText>
                  {personalMessage
                    ? 'Írj személyes üzenetet'
                    : 'Jelentkezz tanfolyamunkra'}
                </HeaderText>
              </Header>
              <FormBody>
                <InputRow>
                  <Icon src={ContactIcon} alt="kapcsolat ikon" />
                  <Input
                    onChange={this.handleChange}
                    error={!!this.state.errors && !!this.state.errors.név}
                    value={this.state.inputs.név.value}
                    autocomplete="off"
                    type="text"
                    name="név"
                    placeholder="Név"
                  />
                </InputRow>
                {this.state.errors && this.state.errors.név && (
                  <ErrorText>{this.state.errors.név}</ErrorText>
                )}
                <InputRow>
                  <Icon src={MailIcon} alt="e-mail ikon" />
                  <Input
                    onChange={this.handleChange}
                    error={!!this.state.errors && !!this.state.errors.email}
                    value={this.state.inputs.email.value}
                    type="email"
                    name="email"
                    placeholder="E-mail cím"
                  />
                </InputRow>
                {this.state.errors && this.state.errors.email && (
                  <ErrorText>{this.state.errors.email}</ErrorText>
                )}
                <InputRow>
                  <Icon src={PhoneIcon} alt="telefon ikon" />
                  <Input
                    onChange={this.handleChange}
                    error={
                      !!this.state.errors && !!this.state.errors.telefonszám
                    }
                    value={this.state.inputs.telefonszám.value}
                    type="text"
                    name="telefonszám"
                    placeholder="Telefonszám"
                  />
                </InputRow>
                {this.state.errors && this.state.errors.telefonszám && (
                  <ErrorText>{this.state.errors.telefonszám}</ErrorText>
                )}
                <InputRow>
                  <Icon src={LocationIcon} alt="település ikon" />
                  <Input
                    onChange={this.handleChange}
                    error={!!this.state.errors && !!this.state.errors.település}
                    value={this.state.inputs.település.value}
                    type="text"
                    name="település"
                    placeholder="Település"
                  />
                </InputRow>
                {this.state.errors && this.state.errors.település && (
                  <ErrorText>{this.state.errors.település}</ErrorText>
                )}
                {personalMessage ? (
                  <>
                    <InputRow>
                      <Icon src={SubjectIcon} alt="tárgy ikon" />
                      <Input
                        onChange={this.handleChange}
                        error={!!this.state.errors && !!this.state.errors.tárgy}
                        value={this.state.inputs.tárgy}
                        type="text"
                        name="tárgy"
                        placeholder="Tárgy"
                      />
                    </InputRow>
                    {this.state.errors && this.state.errors.tárgy && (
                      <ErrorText>{this.state.errors.tárgy}</ErrorText>
                    )}
                  </>
                ) : (
                  <>
                    <InputRow>
                      <Icon src={CategoryIcon} alt="kategória ikon" />
                      <Select
                        name="kategória"
                        error={
                          !!this.state.errors && !!this.state.errors.kategória
                        }
                        value={this.state.inputs.kategória}
                        onChange={this.handleChange}
                      >
                        <option value="default" disabled>
                          Kategória
                        </option>
                        <option value="B">B</option>
                        <option value="A">A</option>
                      </Select>
                      <Arrow src={ArrowIcon} alt="nyíl ikon" />
                    </InputRow>
                    {this.state.errors && this.state.errors.kategória && (
                      <ErrorText>{this.state.errors.kategória}</ErrorText>
                    )}
                  </>
                )}
                {personalMessage ? (
                  <>
                    <InputRow>
                      <Icon textAreaIcon src={MessageIcon} alt="üzenet ikon" />
                      <TextArea
                        name="üzenet"
                        cols="30"
                        rows="5"
                        placeholder="Üzenet"
                        error={
                          !!this.state.errors && !!this.state.errors.üzenet
                        }
                        value={this.state.inputs.üzenet}
                        onChange={this.handleChange}
                      />
                    </InputRow>
                    {this.state.errors && this.state.errors.üzenet && (
                      <ErrorText>{this.state.errors.üzenet}</ErrorText>
                    )}
                  </>
                ) : (
                  <>
                    <InputRow>
                      <Icon src={CategoryIcon} alt="kategória ikon" />
                      <Select
                        name="típus"
                        error={
                          !!this.state.errors && !!this.state.errors.típus
                        }
                        value={this.state.inputs.típus}
                        onChange={this.handleChange}
                      >
                        <option value="default" disabled>Oktatás típusa</option>
                        {
                          hasDate
                            ? (
                              <>
                                <option value="Tantermi oktatás">Tantermi oktatás</option>
                              </>
                            )
                            : null
                        }
                        <option value="Online oktatás">Online oktatás</option>
                      </Select>
                      <Arrow src={ArrowIcon} alt="nyíl ikon" />
                    </InputRow>
                    {this.state.errors && this.state.errors.típus && (
                      <ErrorText>{this.state.errors.típus}</ErrorText>
                    )}
                    {
                      hasDate && this.state.inputs.típus === 'Tantermi oktatás'
                        ? (
                          <InputRow>
                            <Icon src={DateIcon} alt="dátum ikon" />
                            <Input
                              onChange={this.handleChange}
                              type="text"
                              name="dátum"
                              value={datetime}
                              disabled
                            />
                          </InputRow>
                        )
                        : null
                    }
                  </>
                )}
                <ButtonRow center={apply}>
                  <PrimaryButton disabled={this.hasErrorState()} type="submit">
                    {personalMessage ? 'Üzenetküldés' : 'Jelentkezés'}
                  </PrimaryButton>
                  {!apply && (
                    <PrimaryLink to="/apply">vagy írj nekünk!</PrimaryLink>
                  )}
                </ButtonRow>
              </FormBody>
            </StyledContactForm>
          );
        }}
      />
    );
  }
}
