import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, fonts, breakPoints } from '../theme/variables';
import phoneIcon from '../images/phone.svg';
import mailIcon from '../images/mail.svg';
import messengerIcon from '../images/messenger.svg';

const StyledHeader = styled.div`
  background-color: ${colors.primaryLighten};
  font-size: 1.2rem;
  padding: 0 3rem;

  @media (max-width: ${breakPoints.first}) {
    padding: 0;
    font-size: 1.5rem;
  }

  ${props =>
    props.desktop &&
    `
    @media (max-width: ${breakPoints.first}) {
      display: none;
    }
  `}

  ${props =>
    props.tablet &&
    `
    display: none;

    @media (max-width: ${breakPoints.first}) {
      display: block;
    }
  `}

  ${props =>
    props.marginTop &&
    `
    @media (max-width: ${breakPoints.first}) {
      margin-top: ${props.marginTop};
    }
  `}
`;

const ContactBox = styled.ul`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${colors.white};
  padding: 1rem 0;

  @media (max-width: ${breakPoints.first}) {
    flex-direction: column;
    border: 0;
    padding: 0;
  }
`;

const StyledContact = styled.li`
  @media (max-width: ${breakPoints.first}) {
    & + & {
      margin-top: 1.5em;
    }
  }

  a {
    font-family: ${fonts.Nunito};
    letter-spacing: 0.06em;
    color: ${colors.white};
    display: flex;
    align-items: center;

    @media (max-width: ${breakPoints.first}) {
      display: flex;
      justify-content: center;
    }

    img {
      margin-right: 0.5em;
    }
  }
`;

const Contact = ({ url, icon, text }) => (
  <StyledContact>
    <a href={url}>
      <img src={icon.src} alt={icon.alt} />
      {text}
    </a>
  </StyledContact>
);

Contact.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
};

const TopHeader = ({ desktop, tablet, marginTop }) => (
  <StyledHeader desktop={desktop} tablet={tablet} marginTop={marginTop}>
    <ContactBox>
      <Contact
        url="tel:+36-20-418-9286"
        icon={{ src: phoneIcon, alt: 'telefon ikon' }}
        text="+36 20 418 9286"
      />
      <Contact
        url="mailto:beresroland78@gmail.com"
        icon={{ src: mailIcon, alt: 'e-mail ikon' }}
        text="beresroland78@gmail.com"
      />
      <Contact
        url="https://www.messenger.com/t/roland.beres.3"
        icon={{ src: messengerIcon, alt: 'messenger ikon' }}
        text="beres.roland.3"
      />
    </ContactBox>
  </StyledHeader>
);

export default TopHeader;
