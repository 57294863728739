import styled from 'styled-components';
import { Link } from 'gatsby';
import { fonts, colors } from '../theme/variables';

const PrimaryLink = styled(Link)`
  font-family: ${fonts.sourceSansPro};
  font-size: 1.2rem;
  letter-spacing: 0.04em;
  color: ${colors.accentDark};
  transition: color 0.2s ease;

  :hover {
    color: ${colors.accent};
  }

  :active {
    color: ${colors.accentDark};
  }
`;

export default PrimaryLink;
