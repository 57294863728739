import styled from 'styled-components';
import { fonts, colors, breakPoints } from '../theme/variables';

const MainText = styled.article`
  padding: 4rem 8rem;

  @media (max-width: ${breakPoints.first}) {
    padding: 3rem 4rem;
  }

  @media (max-width: ${breakPoints.mobile}) {
    padding: 3rem 2.75rem;
  }

  h2 {
    font-family: ${fonts.Nunito};
    font-style: normal;
    font-weight: 700;
    font-size: 1.8em;
    line-height: normal;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1em;

    color: ${colors.black};
  }

  p {
    font-family: ${fonts.sourceSansPro};
    font-style: normal;
    font-size: 1.8rem;
    line-height: normal;
    text-align: justify;

    color: ${colors.black};
  }

  p + p {
    margin-top: 2em;

    ${props =>
      props.about &&
      `
      margin-top: 1em;
    `}
  }

  img {
    width: 250px;
    display: block;
    margin: 0 auto;

    ${props =>
      props.about &&
      `
      margin: 1em auto 0;
    `}
  }

  h3 {
    font-family: ${fonts.Nunito};
    font-style: normal;
    font-size: 2rem;
    letter-spacing: 0.04em;
    text-align: center;

    color: ${colors.black};

    ${props =>
      props.about &&
      `
      margin-bottom: 2em;
    `}
  }

  a {
    color: ${colors.accentDark};
    transition: color 0.2s ease;

    :hover {
      color: ${colors.accent};
    }

    :active {
      color: ${colors.accentDark};
    }
  }
`;

export default MainText;
